<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs" v-on="on"
                class="ml-2 inline-block text-green font-bold text-xs text-center leading-5 rounded-full px-2 h-5 bg-green-light"
            >{{ episodeNumber }}</span>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'EpisodeOpenTooltip',
    props: {
        episodeNumber: {
            type: Number,
            default: 0
        }
    },
    computed: {
        tooltipText() {
            const programText = this.episodeNumber > 1 ? 'Programs' : 'Program'
            return `${this.episodeNumber} ${programText} Open`
        }
    }
}
</script>
