<template>
    <div v-if="tab === 'CASE_NOTES'">
        <v-btn
            depressed
            data-cy="createCaseNote"
            class="v-btn--flat inline"
            color="success"
            value="submit"
            @click.prevent="createCaseNote"
        >
            <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" />
            Case Note
        </v-btn>
        <!--Hiding Download button until we work on exportng data -->
        <!--
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="ml-2"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                    <fa-icon icon="download" class="mr-1" />
                    <span>Download</span>
                    <fa-icon icon="chevron-down" class="ml-1 text-xs group-hover:text-primary-light" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <v-list-item-title class="text-grey-1">{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        -->
    </div>
    <div v-else-if="tab === 'EPISODES'">
        <v-btn
            v-if="episodeStatusIsReferred"
            depressed
            data-cy="closeEpisode"
            color="danger"
            class="mr-2 v-btn--flat inline bg-red-light mt-2"
            value="submit"
            @click.prevent="setDidNotCommenceStatus"
        >
            Did Not Commence
        </v-btn>
        <v-btn
            v-if="episodeIsOpen"
            depressed
            data-cy="closeEpisode"
            color="danger"
            class="mr-2 v-btn--flat inline bg-red-light mt-2"
            value="submit"
            @click.prevent="closeEpisode"
        >
            <fa-icon icon="times" class="mr-1" />
            Close Program
        </v-btn>
        <v-btn
            v-if="episodeIsClosed && $can('reopen', 'episode')"
            depressed
            color="primary"
            class="mr-2 v-btn--flat inline mt-2"
            value="submit"
            @click.prevent="reopenEpisode"
        >
            <fa-icon icon="redo" class="mr-1" />
            Re-open Program
        </v-btn>

        <v-btn
            v-if="canSetAssessedSuitable"
            depressed
            color="amber lighten-5"
            class="mr-2 v-btn--flat inline mt-2"
            value="submit"
            @click.prevent="assessedSuitable"
        >
            <fa-icon icon="check-circle" class="mr-1" />
            Assessed Suitable
        </v-btn>

        <v-btn
            v-if="canSetAssessedUnsuitable"
            depressed
            color="danger"
            class="mr-2 v-btn--flat inline bg-red-light mt-2"
            value="submit"
            @click.prevent="assessedUnsuitable"
        >
            <fa-icon icon="times-circle" class="mr-1" />
            Assessed Unsuitable
        </v-btn>

        <v-btn
            v-if="canSetAssessedPending"
            depressed
            class="mr-2 v-btn--flat inline text-orange bg-orange-light mt-2"
            value="submit"
            @click.prevent="assessedPending"
        >
            <fa-icon icon="minus-circle" class="mr-1" />
            Assessed Pending
        </v-btn>

        <v-btn
            v-if="canSetOpen"
            depressed
            color="primary"
            class="mr-2 v-btn--flat inline mt-2"
            value="submit"
            @click.prevent="openEpisode"
        >
            Open Program
        </v-btn>
        <v-btn
            v-if="isOutreachAndOpen && $can('reset_to_referred', 'episode')"
            depressed
            color="primary"
            class="mr-2 v-btn--flat inline mt-2"
            value="submit"
            @click.prevent="setToReferred"
        >
            <fa-icon icon="redo" class="mr-1" />
            Set to Referred
        </v-btn>
        <v-menu offset-y>
            <template v-slot:activator="{on, attrs}">
                <v-btn depressed class="ml-2 mt-2" color="primary" v-bind="attrs" v-on="on">
                    <span>Episode Actions</span>
                    <fa-icon
                        icon="chevron-down"
                        class="ml-1 text-xs group-hover:text-primary-light"
                    />
                </v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <v-list-item-title class="cursor-pointer" @click.prevent="createEpisode">
                        <fa-icon icon="plus" class="mr-1" />
                        New Episode
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title
                        v-if="$can('copy', 'episode') && $can('move', 'episode')"
                        class="cursor-pointer"
                        @click.prevent="openMoveOrCopyEpisode"
                    >
                        <fa-icon icon="redo" class="mr-1" />
                        Copy/Move Episode
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title
                        v-if="$can('delete', 'episode')"
                        class="cursor-pointer"
                        @click.prevent="deleteEpisode"
                    >
                        <fa-icon icon="trash-alt" class="mr-1" />
                        Delete Episode
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {EpisodeHelpers} from '@/components/shared/mixins/episodeMixins'

export default {
    name: 'EpisodeActions',
    mixins: [EpisodeHelpers],
    props: {
        tab: {
            type: String,
            default: 'EPISODES'
        },
        currentEpisode: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            items: [
                {title: 'Currently filtered'},
                {title: 'Treatment Events'},
                {title: 'Behaviour Incidents'}
            ]
        }
    },
    methods: {
        createEpisode() {
            this.$root.$emit('create-episode')
        },
        closeEpisode() {
            this.$root.$emit('close-episode')
        },
        reopenEpisode() {
            this.$root.$emit('reopen-episode')
        },
        openEpisode() {
            this.$root.$emit('open-episode')
        },
        createCaseNote() {
            this.$root.$emit('create-case-note')
        },
        setToReferred() {
            this.$root.$emit('set-to-referred')
        },
        assessedSuitable() {
            this.$root.$emit('assessed-suitable')
        },
        assessedUnsuitable() {
            this.$root.$emit('assessed-unsuitable')
        },
        assessedPending() {
            this.$root.$emit('assessed-pending')
        },
        setDidNotCommenceStatus() {
            // console.log("currentEpisode", this.currentEpisode);
            this.$root.$emit('set-to-did-not-commence')
        },
        openMoveOrCopyEpisode() {
            console.log('currentEpisode', this.currentEpisode)
            this.$root.$emit('open-move-or-copy-episode')
        },
        deleteEpisode() {
            this.$root.$emit('delete-episode')
        }
    },
    computed: {
        ...mapState({
            siteSections: (state) => state.app.siteSections
        }),
        episodeIsOpen() {
            return this.currentEpisode && this.currentEpisode.status === 'OPEN'
        },
        episodeIsClosed() {
            return this.currentEpisode && this.currentEpisode.status === 'CLOSED'
        },
        episodeIsPending() {
            return this.currentEpisode && this.currentEpisode.status === 'PENDING'
        },
        episodeIsSuitable() {
            return this.currentEpisode && this.currentEpisode.status === 'SUITABLE'
        },
        episodeIsUnsuitable() {
            return this.currentEpisode && this.currentEpisode.status === 'UNSUITABLE'
        },
        episodeIsReferred() {
            return this.currentEpisode && this.currentEpisode.status === 'REFERRED'
        },
        episodeIsPendingAndComprehensiveComplete() {
            //Please add logic for comprehensive assessment is complete (all required fields completed)
            // return this.currentEpisode && this.currentEpisode.status === 'PENDING'
            return this.currentEpisode && this.currentEpisode.type === 'RESIDENTIAL'
        },
        episodeIsReferredOrSuitable() {
            console.log('currentEpisode', this.currentEpisode)
            return this.currentEpisode && (this.episodeIsSuitable || this.episodeIsReferred)
        },
        isOutreachAndOpen() {
            return (
                this.currentEpisode &&
                ['OPEN', 'DID_NOT_COMMENCE'].indexOf(this.currentEpisode.status) > -1
                // && this.currentEpisode.type === 'NON_RESIDENTIAL'
            )
        },
        episodeStatusIsReferred() {
            return this.currentEpisode && this.currentEpisode.status === 'REFERRED'
        },
        checkTypeEpisodeForAssessedButton() {
            return (
                this.currentEpisode &&
                String(this.getSiteSection(this.currentEpisode)).includes('PALM')
            )
        },
        canSetOpen() {
            if (this.episodeIsPendingAndComprehensiveComplete && this.episodeIsSuitable)
                return this.$can('open', 'episode')
            else if (!this.episodeIsPendingAndComprehensiveComplete && this.episodeIsReferred)
                return this.$can('open', 'episode')
            return false
        },
        canSetAssessedSuitable() {
            return this.episodeIsPending && this.$can('open', 'episode')
        },
        canSetAssessedUnsuitable() {
            return (this.episodeIsPending || this.episodeIsSuitable) && this.$can('open', 'episode')
        },
        canSetAssessedPending() {
            if (this.episodeIsUnsuitable) {
                return (
                    this.checkTypeEpisodeForAssessedButton &&
                    this.$can('reset_to_assessed_pending', 'episode')
                )
            }
            return (
                (this.episodeIsSuitable || this.episodeIsReferred) &&
                this.checkTypeEpisodeForAssessedButton &&
                this.$can('reset_suitable_to_assessed_pending', 'episode')
            )
        },
        canViewAssessedButtons() {
            return (
                (this.episodeIsSuitable || this.episodeIsReferred) &&
                this.checkTypeEpisodeForAssessedButton &&
                this.$can('reset_suitable_to_assessed_pending', 'episode')
            )
        }
    }
}
</script>
