<template>
    <div class="flex flex-col xl:flex-row justify-between items-center">
        <div class="flex-col w-full xl:w-auto mb-4 lg:mb-0">
            <div>
                <label class="pl-2 text-black text-xs uppercase font-bold">EPISODE</label>

                <!--Display only if there is more than 1 'Program Open' episode-->
                <EpisodeOpenTooltip
                    v-if="hasOpenEpisodes"
                    :episode-number="episodeNumber"
                />
                <!--end-->
            </div>

            <div v-if="!episodes.length" class="px-2 text-sm text-grey-2 w-full xl:w-auto">
                No episodes for this client.
            </div>
            <v-menu offset-y v-else class="w-full xl:w-auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        depressed
                        class="bg-white-pure px-0 w-full episode-dropdown-button"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <div class="w-full px-2 flex justify-start items-center text-sm font-normal text-grey-1 text-left">
                            <div class="min-w-daterange flex-none">{{ getDateRange(selectedEpisode) }}</div>
                            <div class="w-40 uppercase font-bold flex-none" :class="getStatusColour(selectedEpisode)">
                                {{ getEpisodeStatus(selectedEpisode) }}
                            </div>
                            <div class="mr-4 w-sitesection truncate flex-none font-bold text-black">{{ getSiteSection(selectedEpisode) }}
                            </div>  
                            <div class="mr-2 w-30 flex-none truncate">{{ getWorker(selectedEpisode) }}</div>
                            <fa-icon icon="caret-down" class="text-grey-2 ml-3" />
                        </div>
                    </v-btn>
                </template>
                <v-list class="episode-dropdown-list">
                    <v-list-item
                        v-for="(episode, index) in episodes"
                        :key="`episode-${index}`"
                        :disabled="isEpisodeLocked(episode)"
                        :class="{'bg-grey-6': isEpisodeLocked(episode)}"
                        @click="menuItemClickHandler(episode)"
                    >
                        <v-list-item-title class="text-grey-1">
                            <div class="px-2 flex justify-start items-center text-sm">
                                <div class="min-w-daterange flex-none">{{ getDateRange(episode) }}</div>
                                <div class="w-40 uppercase font-bold flex-none" :class="getStatusColour(episode)">
                                    {{ getEpisodeStatus(episode) }}
                                </div>
                                <div class="mr-4 w-sitesection truncate flex-none">{{ getSiteSection(episode) }}</div>
                                <div class="mr-2 w-30 flex-none capitalize">{{ getWorker(episode) }}</div>
                                <!--below icon will display 'lock icon' for episodes the user doesnt have access to-->                              
                                <fa-icon icon="caret-down" class="opacity-0 text-grey-2 ml-3" />
                                <fa-icon v-if="isEpisodeLocked(episode)" :icon="['fas', 'lock']" class="text-grey-2 ml-3" />
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <EpisodeActions :tab="tab" :current-episode="selectedEpisode" class="w-full xl:w-auto"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EpisodeActions from '@/components/episodes/partials/EpisodeActions'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'
import EpisodeOpenTooltip from '@/components/shared/EpisodeOpenTooltip'

export default {
    name: "EpisodeHeader",
    components: { EpisodeOpenTooltip, EpisodeActions },
    mixins: [ EpisodeHelpers ],
    props: {
        episodes: {
            type: Array,
            default: () => []
        },
        episode: {
            type: Object,
            default: () => {}
        },
        tab: {
            type: String,
            default: 'EPISODES'
        }
    },
    data() {
        return {
            // episodeId: null,
            show: false,
            defaultEpisode: {
                episode: 'TEST_1'
            },
            selectedEpisode: null,
            // firstLoad: true
        }
    },
    mounted() {
        this.selectedEpisode = this.episode
    },
    methods: {
        menuItemClickHandler(episode){
            this.selectedEpisode = {...episode}
            // this.episodeId = episode.episodeId
        },
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections,
            assignedToMe: state => state.app.assignedToMe
        }),
        episodeStatuses() {
            return this.staticChoices['episode.status']
        },
        episodeNumber() {
            //this should total number of episodes with status PROGRAM OPEN
            return this.episodes.filter(e => e.status === 'OPEN').length
        },
        hasOpenEpisodes() {
            //this should total number of episodes with status PROGRAM OPEN
            return this.episodes && this.episodes.filter(e => e.status === 'OPEN').length > 0
        },
        episodeForm: {
            get() {
                return this.defaultEpisode
            },
            set(episodeForm) {
                console.log('episodeForm', episodeForm)
            }
        },
    },
    watch: {
        episodes() {
            // checking if the list of episodes have changed or not
            const idx = this.episodes.findIndex(e => e.episodeId === this.selectedEpisode?.episodeId)
            if (idx > -1) return

            this.selectedEpisode = this.episodes.find(
                episode =>
                    !this.isEpisodeLocked(episode) &&
                    (!this.assignedToMe || this.loggedInUser?.userId === episode.userId)
            )
        },
        selectedEpisode() {
            if (this.selectedEpisode && this.episode.episodeId !== this.selectedEpisode.episodeId) {
                this.$emit('episode-changed', this.selectedEpisode)
            }
        },
        episode() {
            if (this.episode) this.selectedEpisode = this.episode
        }
    }
}
</script>
